<template>
  <b-row>
    <b-col cols="12">
      <h2>Paquetes</h2>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>

    <div class="col-12 mt-16">
      <datatable
        :items="items"
        :fields="fields"
        @changePerPage="perPageChanged"
      >
        <template slot="filters">
          <b-col cols="3" class="my-3">
            <b-button
              variant="primary"
              class="btn-ghost"
              v-b-modal.modal-add-document-admin
              @click="resetForm"
            >
              Añadir nuevo paquete
            </b-button>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Seleccione departamento"
              label-for="filter-input"
              label-cols-sm="2"
              label-align-sm="left"
              label-size="sm"
              class="mb-0"
            >
              <b-row>
                <b-col cols="7">
                  <b-form-select
                    size="sm"
                    v-model="documentCreate.department_uuid"
                    :options="Departaments"
                    @change="onChangeDepartament"
                  ></b-form-select>
                </b-col>
                <b-button
                  @click="clearSelection"
                  variant="primary"
                  class="btn-ghost mt-2"
                  size="sm"
                >
                  Limpiar
                </b-button>
              </b-row>
            </b-form-group>
          </b-col>
        </template>

        <template slot-scope="{ item }" slot="actions">
          <b-row class="actions-icons">
            <span
              title="ver"
              @click="show(item.uuid)"
              :id="'_show_button_' + item.uuid"
            >
              <i class="iconly-Light-Show"></i>
            </span>
            <span
              title="editar"
              @click="edit(item.uuid)"
              :id="'_show_editar' + item.uuid"
              :class="{ disabled: item.isExpired }"
              :style="{
                opacity: item.isExpired ? 0.5 : 1,
                cursor: item.isExpired ? 'not-allowed' : 'pointer',
              }"
            >
              <i class="iconly-Light-Edit mr-10"></i>
            </span>
            <span
              title="eliminar"
              @click="showDeleteConfirmation(item.uuid)"
              :id="'_show_eliminar' + item.uuid"
            >
              <i class="iconly-Light-Delete mr-10"></i>
            </span>
            <span
              title="quien retiro"
              @click="package_receive(item)"
              :id="'_show_retiro' + item.uuid"
            >
              <i class="iconly-Bold-User mr-10"></i>
            </span>
          </b-row>

          <b-tooltip :target="'_show_button_' + item.uuid" triggers="hover">
            Ver
          </b-tooltip>

          <b-tooltip :target="'_show_editar' + item.uuid" triggers="hover">
            Editar
          </b-tooltip>

          <b-tooltip :target="'_show_eliminar' + item.uuid" triggers="hover">
            Eliminar
          </b-tooltip>

          <b-tooltip :target="'_show_retiro' + item.uuid" triggers="hover">
            Entregar paquete
          </b-tooltip>
        </template>

        <template slot="pagination">
          <b-pagination
            align="end"
            :total-rows="totalRows"
            :per-page="totalRowsPerpage"
            @change="pageChanged"
          ></b-pagination>
        </template>
      </datatable>
    </div>

    <!-- create document -->
    <b-modal
      size="xl"
      id="modal-add-document-admin"
      cancel-variant="text"
      body-class="py-50"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      content-class="modal-add-document-admin"
      modal-class="hp-profile-modal-1"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Añadir nuevo paquete</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form inline>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Seleccione departamento">
              <b-form-select
                v-model="documentCreate.department_uuid"
                :options="Departaments"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Nombre">
              <b-form-input
                v-model="documentCreate.name"
                type="text"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Empresa">
              <b-form-input
                v-model="documentCreate.company"
                type="text"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Tipo de Documento">
              <b-form-select
                v-model="documentCreate.dni_type_uuid"
                :options="DniType"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            v-if="
              documentCreate.dni_type_uuid == 4 ||
              documentCreate.dni_type_uuid == 3
            "
          >
            <b-form-group label="RUT">
              <imask-input
                class="form-control"
                :value="documentCreate.dni"
                v-imask="dniMask"
                :unmask="true"
                @complete="onCompleteDni"
                placeholder="00.000.000-00"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            v-if="
              documentCreate.dni_type_uuid != 4 &&
              documentCreate.dni_type_uuid != 3
            "
          >
            <b-form-group label="Documento">
              <b-form-input
                v-model="documentCreate.dni"
                type="text"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Comentario">
              <b-form-textarea
                v-model="documentCreate.comment"
                type="text"
              ></b-form-textarea>
            </b-form-group>
          </b-col>

          <b-col cols="6 pr-12">
            <b-button variant="primary" block @click="submitFile">
              Añadir
            </b-button>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-add-document-admin')"
            >
              Cerrar
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>

    <!-- edit document -->
    <b-modal
      size="xl"
      id="modal-edit-document-admin"
      cancel-variant="text"
      body-class="py-50"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      content-class="modal-edit-document-admin"
      modal-class="hp-profile-modal-1"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Editar paquete</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form inline>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Seleccione departamento">
              <b-form-select
                v-model="documentCreateEdit.department_uuid"
                :options="Departaments"
                @change="onEditDepartement"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Nombre">
              <b-form-input
                v-model="documentCreate.name"
                type="text"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Empresa">
              <b-form-input
                v-model="documentCreate.company"
                type="text"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Tipo de Documento">
              <b-form-select
                v-model="documentCreate.dni_type_uuid"
                :options="DniType"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            v-if="
              documentCreate.dni_type_uuid == 4 ||
              documentCreate.dni_type_uuid == 3
            "
          >
            <b-form-group label="RUT">
              <imask-input
                class="form-control"
                :value="documentCreate.dni"
                v-imask="dniMask"
                :unmask="true"
                @complete="onCompleteDni"
                placeholder="00.000.000-00"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            v-if="
              documentCreate.dni_type_uuid != 4 &&
              documentCreate.dni_type_uuid != 3
            "
          >
            <b-form-group label="Documento">
              <b-form-input
                v-model="documentCreate.dni"
                type="text"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Comentario">
              <b-form-textarea
                v-model="documentCreate.comment"
                type="text"
              ></b-form-textarea>
            </b-form-group>
          </b-col>

          <b-col cols="6 pr-12">
            <b-button variant="primary" block @click="submitFileEdit">
              Añadir
            </b-button>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-edit-document-admin')"
            >
              Cerrar
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>

    <!-- View document detail -->
    <b-modal
      id="modal-detail-document-admin"
      cancel-variant="text"
      body-class="py-48"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      modal-class="modal-detail-document-admin"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Ver detalle del paquete</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Nombre">
              <b-form-input
                v-model="documentCreate.name"
                type="text"
                :disabled="true"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group label="Empresa">
              <b-form-input
                v-model="documentCreate.company"
                type="text"
                :disabled="true"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-8">
            <b-form-group label="RUT">
              <b-form-input
                v-model="documentCreate.dni"
                type="text"
                :disabled="true"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-8">
            <b-form-group label="Comentario">
              <b-form-textarea
                v-model="documentCreate.comment"
                type="text"
                :disabled="true"
              ></b-form-textarea>
            </b-form-group>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-detail-document-admin')"
            >
              Cerrar
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>

    <!-- View document package -->
    <b-modal
      id="modal-package-document-admin"
      cancel-variant="text"
      body-class="py-48"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      modal-class="modal-package-document-admin"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 v-if="buttonRegister">
            ¿ Desea registrar este paquete con los datos de este usuario ?
          </h5>
          <h5 v-else class="mb-0">Ver Entrega del paquete</h5>

          <b-button
            variant="text"
            @click="
              () => {
                close();
                buttonRegister = false;
              }
            "
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form>
        <b-row>
          <b-col cols="12">
            <b-form-group
              v-if="!userNoExist || buttonRegister"
              label="E-mail :"
              label-for="recoverEmail"
              class="mb-16"
            >
              <b-form-input
                v-model="searchUser.email"
                @input="validateEmail"
                :class="{ 'is-invalid': emailError }"
                placeholder="escriba un correo"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col v-if="userNoExist" cols="12">
            <b-form-group label="Nombre y apellido">
              <b-form-input
                v-model="delivered_other.delivered_other_name"
                type="text"
                :disabled="disableCamp || buttonRegister"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col v-if="userNoExist" cols="12" class="mt-8">
            <b-form-group label="RUT">
              <b-form-input
                v-model="delivered_other.delivered_other_dni"
                @input="maskdni"
                placeholder="00.000.000-00"
                maxlength="12"
                type="text"
                :disabled="disableCamp || buttonRegister"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col
            v-if="(userNoExist || buttonRegister) && !disableCamp"
            cols="6"
            class="pl-12"
          >
            <b-button
              variant="primary"
              @click="submitFileEdit(false)"
              class="btn btn-ghost btn-primary"
            >
              registrar
            </b-button>
            <div v-if="emailError" class="invalid-feedback d-block">
              {{ emailError }}
            </div>
          </b-col>
          <b-col
            v-if="(userNoExist || buttonRegister) && !disableCamp"
            cols="6"
            class="pl-12"
          >
            <b-button
              variant="primary"
              @click="
                () => {
                  $bvModal.hide('modal-package-document-admin');
                  buttonRegister = false;
                }
              "
              class="btn btn-ghost btn-primary"
            >
              Cancelar
            </b-button>
          </b-col>

          <b-col v-if="!userNoExist && !buttonRegister" cols="6" class="pl-12">
            <b-button
              variant="primary"
              @click.prevent="SearchUserEmail"
              class="btn btn-ghost btn-primary"
              :disabled="emailError || !searchUser.email"
            >
              buscar
            </b-button>
            <div v-if="emailError" class="invalid-feedback d-block">
              {{ emailError }}
            </div>
          </b-col>
        </b-row>
      </form>
    </b-modal>

    <!-- Modal de confirmación de eliminación -->
    <b-modal
      id="modal-delete-confirmation"
      title="eliminar"
      cancel-variant="text"
      body-class="py-48"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      modal-class="modal-delete-confirmation"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Confirmar eliminación</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <b-row>
        <b-col cols="12">
          <p class="text-center">
            ¿Estás seguro de que deseas eliminar este paquete?
          </p>
        </b-col>

        <b-col cols="6" class="pr-2">
          <b-button variant="danger" block @click="confirmDelete">
            Sí, eliminar
          </b-button>
        </b-col>

        <b-col cols="6" class="pl-2">
          <b-button
            variant="none"
            block
            @click="$bvModal.hide('modal-delete-confirmation')"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </b-row>
</template>

<script>
import {
  BCard,
  BTable,
  BBadge,
  BButton,
  BRow,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
  BFormFile,
  BFormSelectOption,
  BFormDatepicker,
  BCalendar,
  BPagination,
} from "bootstrap-vue";

import datatable from "@/layouts/components/datatable/Datatable.vue";
import sweet from "@/logic/functions/sweetAlert";
import users from "@/logic/functions/users";
import packages from "@/logic/functions/packages";
import departaments from "@/logic/functions/departaments";
import dni from "@/logic/functions/dni";
import { DateTime } from "luxon";
import { IMaskComponent, IMaskDirective, useIMask } from "vue-imask";
import validate_dni from "@/logic/functions/users/validate_dni";

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BButton,
    BRow,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect, // this
    BFormSelectOption,
    BFormDatepicker,
    BCalendar, //this
    BFormFile,
    BPagination,
    datatable,
    "imask-input": IMaskComponent,
  },
  directives: {
    imask: IMaskDirective,
  },
  data() {
    return {
      itemToDelete: null,
      searchUser: {
        email: "",
      },
      emailError: null,
      fields: [
        { key: "company", label: "Empresa" },
        {
          key: "department_uuid",
          label: "Departamento",
          formatter: (value, key, item) => {
            return item.department_uuid.nro;
          },
        },
        {
          key: "created_at",
          label: "Fecha de recepción",
          formatter: (value, key, item) => {
            return (
              DateTime.fromISO(item.created_at).toFormat("dd/MM/yyyy HH:mm ") +
              "hrs"
            );
          },
        },
        { key: "actions", label: "Acciones" },
      ],
      Actions: [],
      items: [],
      loadNewUsers: [],
      codeText: "",
      codeActive: false,
      codeActiveClass: false,
      flags: {
        formUpload: false,
        fileUpload: false,
      },
      Departaments: [],
      DniType: [],
      createSales: {},
      documentCreate: {
        dni_type_uuid: 3,
      },
      dniIsValid: false,
      dniMask: {
        mask: "00.000.000-{[*]}",
        lazy: false,
      },
      paginationData: {},
      totalRows: 0,
      totalRowsPerpage: 5,
      currentPage: 0,
      departament_filter: "",
      userNoExist: false,
      delivered_other: {
        delivered_by: "",
        delivered_other_name: "",
        delivered_other_dni: "",
      },
      buttonRegister: false,
      assignUser: null,
      disableCamp: false,
      documentCreateEdit: {
        dni_type_uuid: 3,
      },
    };
  },
  computed: {
    itemsWithExpirationStatus() {
      return this.items.map((item) => ({
        ...item,
        isExpired: this.isExpired(item),
      }));
    },
  },

  methods: {
    isExpired(item) {
      const now = DateTime.now().setZone("America/Santiago");
      const expireTime = DateTime.fromISO(item.put_expire_time).setZone(
        "America/Santiago"
      );
      return now > expireTime;
    },
    resetForm() {
      this.documentCreate = {
        dni_type_uuid: 3,
      };
    },
    validateEmail() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(this.searchUser.email)) {
        this.emailError = "Por favor, ingrese un correo electrónico válido.";
      } else {
        this.emailError = null;
      }
    },
    SearchUserEmail() {
      if (!this.emailError) {
        users
          .searchuseremail(this.searchUser.email)
          .then((response) => {
            if (response.data.uuid) {
              sweet.ToastMsg("", "error", "el usuario ya existe");
              this.delivered_other.delivered_other_dni = response.data.dni;
              this.delivered_other.delivered_other_name = response.data.name;
              this.buttonRegister = true;
              this.userNoExist = true;
              this.assignUser = response.data;
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 404) {
              sweet.ToastMsg("", "error", "el usuario no existe");
              this.userNoExist = true;
              this.searchUser.email = "";
            } else {
              sweet.ToastMsg("", "error", "El usuario ya existe");
            }
          });
      }
    },
    clearSelection() {
      this.documentCreate.department_uuid = "";
      this.departament_filter = this.documentCreate.department_uuid;
      this.documents();
    },
    onChangeDepartament() {
      this.departament_filter = this.documentCreate.department_uuid;
      this.documents();
    },
    onCompleteDni(e) {
      let dni = e.detail._value.replace(/\./g, "");
      if (validate_dni.validaRut(dni)) {
        this.dniIsValid = true;
        this.documentCreate.dni = e.detail._value;
        e.target.style.border = "none";
      } else {
        this.dniIsValid = false;
        e.target.style.border = "3px solid #FF0000";
      }
    },
    package_receive(item) {
      if (this.isExpired(item)) {
        sweet.ToastMsg(
          "",
          "error",
          "Expirado: Tiempo excedido. Dispone solo de 30min para editar o eliminar la recepción de un paquete luego de creado."
        );
        return;
      }
      this.searchUser.email = "";
      this.documentCreate = item;
      if (item.delivered_other_dni && item.delivered_other_name) {
        this.delivered_other.delivered_other_dni = item.delivered_other_dni;
        this.delivered_other.delivered_other_name = item.delivered_other_name;
        this.userNoExist = true;
        this.disableCamp = true;
      } else {
        this.delivered_other.delivered_other_dni = null;
        this.delivered_other.delivered_other_name = null;
        this.userNoExist = false;
        this.disableCamp = false;
      }

      this.$bvModal.show("modal-package-document-admin");
    },
    show(uuid) {
      this.documentCreate = this.items.find((item) => {
        return item.uuid == uuid;
      });

      this.$bvModal.show("modal-detail-document-admin");
    },
    edit(uuid) {
      const item = this.itemsWithExpirationStatus.find(
        (item) => item.uuid === uuid
      );
      if (item && !item.isExpired) {
        this.documentCreate = {};
        this.documentCreate = this.items.find((item) => {
          return item.uuid == uuid;
        });
        // Asegurarse de que department_uuid sea el valor correcto (UUID)
        if (
          this.documentCreate.department_uuid &&
          typeof this.documentCreate.department_uuid === "object"
        ) {
          this.documentCreateEdit.department_uuid =
            this.documentCreate.department_uuid.uuid;
        }
        this.$bvModal.show("modal-edit-document-admin");
      } else {
        sweet.ToastMsg(
          "",
          "error",
          "Expirado: Tiempo excedido. Dispone solo de 30min para editar o eliminar la recepción de un paquete luego de creado."
        );
      }
    },

    showDeleteConfirmation(uuid) {
      const item = this.itemsWithExpirationStatus.find(
        (item) => item.uuid === uuid
      );
      if (item && !item.isExpired) {
        this.itemToDelete = uuid;
        this.$bvModal.show("modal-delete-confirmation");
      } else {
        sweet.ToastMsg(
          "",
          "error",
          "Expirado: Tiempo excedido. Dispone solo de 30min para editar o eliminar la recepción de un paquete luego de creado."
        );
      }
    },
    confirmDelete() {
      if (this.itemToDelete) {
        this.deletes(this.itemToDelete);
        this.$bvModal.hide("modal-delete-confirmation");
      }
    },
    deletes(uuid) {
      let items = this.items;
      packages
        .delete(uuid)
        .then((response) => {
          const index = items.findIndex((item) => item.uuid === uuid);
          if (~index) {
            items.splice(index, 1);
          }
          sweet.ToastMsg("", "success", "Paquete eliminado");
        })
        .catch((error) => {});
    },
    async documents(page = 0, limit = 5, departmentUuid = "") {
      await packages
        .list({
          params: {
            department_uuid: this.departament_filter || departmentUuid,
            limit: this.totalRowsPerpage || limit,
            page: this.currentPage || page,
          },
        })
        .then((response) => {
          this.items = response.data.data;
          this.totalRows = response.data.items;
          this.totalRowsPerpage = response.data.limit;
        })
        .catch((error) => {});
    },
    async submitFile() {
      await packages
        .create({
          company: this.documentCreate.company,
          comment: this.documentCreate.comment,
          department_uuid: this.documentCreate.department_uuid,
          dni: this.documentCreate.dni,
          dni_type_uuid: this.documentCreate.dni_type_uuid.toString(),
          name: this.documentCreate.name,
        })
        .then((response) => {
          this.documents();
          this.$bvModal.hide("modal-add-document-admin");
          sweet.ToastMsg("", "success", "Paquete creado");
        })
        .catch((error) => {
          this.flags.fileUpload = false;
          sweet.ToastMsg("", "error", "Paquete no creado");
        });
    },
    onEditDepartement(departament) {
      this.documentCreate.department_uuid = departament;
    },
    async submitFileEdit(isEdit = true) {
      let body = null;
      if (isEdit) {
        body = {
          company: this.documentCreate.company,
          comment: this.documentCreate.comment,
          department_uuid: this.documentCreate.department_uuid,
          dni: this.documentCreate.dni,
          dni_type_uuid: this.documentCreate.dni_type_uuid.toString(),
          name: this.documentCreate.name,
        };
      } else {
        body = {
          delivered_by: this.assignUser ? this.assignUser.uuid : null,
          delivered_other_dni: this.assignUser
            ? null
            : this.delivered_other.delivered_other_dni,
          delivered_other_name: this.assignUser
            ? null
            : this.delivered_other.delivered_other_name,
        };
      }
      await packages
        .update(body, this.documentCreate.uuid)
        .then((response) => {
          this.documents();
          this.$bvModal.hide("modal-edit-document-admin");
          this.$bvModal.hide("modal-package-document-admin");

          sweet.ToastMsg("", "success", "Paquete registrado");
        })
        .catch((error) => {
          this.flags.fileUpload = false;
          sweet.ToastMsg("", "error", "Paquete no editado");
        });
    },
    async listDepartaments() {
      await departaments
        .list()
        .then((response) => {
          Object.entries(response.data.data).forEach((entry) => {
            const [key, value] = entry;
            this.Departaments.push({ value: value.uuid, text: value.nro });
          });
        })
        .catch((error) => {});
    },
    async listDniType() {
      await dni
        .list()
        .then((response) => {
          Object.entries(response.data.data).forEach((entry) => {
            const [key, value] = entry;
            this.DniType.push({ value: value.uuid, text: value.name });
          });
        })
        .catch((error) => {});
    },
    async pageChanged(page) {
      page = page == 1 ? 0 : page - 1;
      this.currentPage = page;
      this.documents(page);
    },
    async perPageChanged(perPage) {
      this.totalRowsPerpage = perPage;
      this.currentPage = 0;
      this.documents(0, perPage);
    },
    maskdni(e) {
      var inputValue = e;

      var dni = inputValue.replace(
        /(\d{2})(\d{3})(\d{3})(\d{1})/,
        "$1.$2.$3-$4"
      );

      this.documentCreate.dni = dni;
    },
  },
  watch: {
    totalRowsPerpage(newValue) {
      console.log(newValue);
    },
  },
  beforeDestroy() {
    if (this.expirationInterval) {
      clearInterval(this.expirationInterval);
    }
  },
  mounted() {
    this.listDepartaments();
    this.listDniType();
    this.documents();
    this.expirationInterval = setInterval(() => {
      this.items = [...this.items]; // Esto forzará una actualización de itemsWithExpirationStatus
    }, 60000);
  },
};
</script>
<style>
.modal {
  /* Estilos para el modal */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}
</style>
